import React from 'react';
import { Container, Box, Typography, Divider } from '@mui/material';
import BlackAppBar from '../components/BlackAppBar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import usePageTitle from '../components/usePageTitle';

export default function AboutUsPage() {
  usePageTitle('BEC | About Us');

  return (
    <BlackAppBar>
      <Container
        maxWidth="xl"
        sx={{
          backgroundColor: '#000',
          color: 'white',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {/* Header */}
        <Header />

        {/* Divider for consistency */}
        <Divider sx={{ backgroundColor: 'white' }} />

        {/* Main Content */}
        <Box sx={{ flex: 1, textAlign: 'center', py: 4 }}>
          <Typography
            variant="h5"
            sx={{
              mt: 2,
              mb: 2,
              fontSize: { xs: '1.6rem', sm: '1.6rem', md: '1.8rem' }, // Responsive font size
            }}
          >
            About BEC
          </Typography>
          <Typography
            variant="body1"
            sx={{
              opacity: 0.8,
              maxWidth: '800px',
              mx: 'auto',
              mb: 4,
              fontSize: { xs: '0.8rem', sm: '1rem', md: '1.2rem' }, // Responsive font size
            }}
          >
            Black Eternum Capital is dedicated to advancing Kenya's financial landscape through innovative trading technology and educational resources. Our commitment is to empower traders and investors with cutting-edge tools tailored to the unique dynamics of Kenya's financial markets.
          </Typography>

          {/* Vision */}
          <Typography
            variant="h5"
            sx={{
              mt: 6,
              mb: 2,
              fontSize: { xs: '1.6rem', sm: '1.6rem', md: '1.8rem' }, // Responsive font size
            }}
          >
            Our Vision
          </Typography>
          <Typography
            variant="body2"
            sx={{
              opacity: 0.8,
              maxWidth: '600px',
              mx: 'auto',
              fontSize: { xs: '0.8rem', sm: '1rem', md: '1.1rem' }, // Responsive font size
            }}
          >
            To be the leading provider of technology-driven financial solutions that foster growth and stability in Kenya's trading sector, creating a more informed and empowered investor community.
          </Typography>

          {/* Mission */}
          <Typography
            variant="h5"
            sx={{
              mt: 6,
              mb: 2,
              fontSize: { xs: '1.6rem', sm: '1.6rem', md: '1.8rem' }, // Responsive font size
            }}
          >
            Our Mission
          </Typography>
          <Typography
            variant="body2"
            sx={{
              opacity: 0.8,
              maxWidth: '600px',
              mx: 'auto',
              fontSize: { xs: '0.8rem', sm: '1rem', md: '1.1rem' }, // Responsive font size
            }}
          >
            Black Eternum Capital’s mission is to democratize access to financial resources, education, and trading tools, facilitating greater market participation and driving economic empowerment.
          </Typography>
        </Box>

        {/* Footer */}
        <Footer />
      </Container>
    </BlackAppBar>
  );
};
