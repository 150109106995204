import React, { useState } from 'react';
import { Box, Toolbar, Typography, Button, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DrawerMenu from './DrawerMenu';

const Header = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const handleDrawerOpen = () => setIsDrawerOpen(true);
    const handleDrawerClose = () => setIsDrawerOpen(false);

    return (
        <>
            <Toolbar disableGutters>
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                        flexGrow: 1,
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'white',
                        textDecoration: 'none',
                        display: 'flex',
                        fontSize: { xs: '0.8rem', sm: '1rem', md: '1.2rem' },
                    }}
                >
                    BLACK ETERNUM CAPITAL
                </Typography>
                <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 0 }}>
                    <Button href="/products" sx={{ color: 'white', textTransform: 'none' }}>
                        Products
                    </Button>
                    <Button href="/about-us" sx={{ color: 'white', textTransform: 'none' }}>
                        About
                    </Button>
                </Box>
                <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 0, ml: 'auto' }}>
                    <IconButton size="large" aria-label="menu" onClick={handleDrawerOpen} color="inherit">
                        <MenuIcon />
                    </IconButton>
                </Box>
            </Toolbar>

            {/* DrawerMenu Component */}
            <DrawerMenu open={isDrawerOpen} onClose={handleDrawerClose} />
        </>
    );
};

export default Header;
