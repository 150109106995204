import React from 'react';
import Box from '@mui/material/Box';

const Footer = () => (
    <Box
        sx={{
            position: 'fixed',  // Use 'fixed' to keep it at the bottom without causing overflow
            bottom: 0,
            left: 0,  // Aligns it to the viewport's left edge
            width: '100vw',  // Full viewport width, preventing overflow
            py: 1,
            textAlign: 'center',
            backgroundColor: '#000',
            color: 'white',
            fontSize: { xs: '0.8rem', sm: '0.9rem' },
        }}
    >
        © {new Date().getFullYear()} Black Eternum Capital
    </Box>
);

export default Footer;
