import React from 'react';
import { useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';

const DrawerMenu = ({ open, onClose }) => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path); // Navigate to the specified path
    onClose(); // Close the drawer after navigating
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: '40%',
          backgroundColor: 'black',
          color: 'white',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '8px',
          marginRight: '12px',
        }}
      >
        <IconButton onClick={onClose} color="inherit">
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider sx={{ backgroundColor: 'white' }} />
      <Box sx={{ padding: '16px' }}>
        <MenuItem onClick={() => handleNavigation('/products')}>
          <Typography textAlign="center" sx={{ color: 'white', fontSize: '1.2rem' }}>
            Product
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handleNavigation('/about-us')}>
          <Typography textAlign="center" sx={{ color: 'white', fontSize: '1.2rem' }}>
            About
          </Typography>
        </MenuItem>
      </Box>
    </Drawer>
  );
};

export default DrawerMenu;
