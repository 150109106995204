import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const MainContent = () => (
    <Grid
        container
        sx={{
            flex: 1,  // Ensures it takes remaining space
            backgroundColor: '#000',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            overflow: 'hidden',
            padding: { xs: 2, md: 4 },
        }}
    >
        <Grid item xs={12} md={8}>
            <Typography
                variant="h4"
                sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    mb: 2,
                    fontSize: { xs: '1.3rem', sm: '1.8rem', md: '2.3rem' },
                }}
            >
                We are dedicated to financial education and resource distribution in Kenya’s trading industry.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    color: 'white',
                    opacity: 0.8,
                    fontSize: { xs: '0.9rem', sm: '1rem', md: '1.2rem' },
                    maxWidth: '80%',
                    mx: 'auto',
                    mb: 2,
                }}
            >
                Our mission is to empower traders and investors by providing access to essential resources and knowledge tailored to Kenya’s dynamic market.
            </Typography>
        </Grid>
    </Grid>
);

export default MainContent;
