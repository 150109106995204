import React from 'react';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import BlackAppBar from '../components/BlackAppBar';
import Header from '../components/Header';
import MainContent from '../components/MainContent';
import Footer from '../components/Footer';
import usePageTitle from '../components/usePageTitle';

export default function Home() {
  usePageTitle('BEC | Home');

  return (
    <BlackAppBar>
      <Container maxWidth="xl" sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Header />
        <Divider sx={{ backgroundColor: 'white' }} />
        <MainContent />
        <Footer />
      </Container>
    </BlackAppBar>
  );
}
