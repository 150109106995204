import React from 'react';
import AppBar from '@mui/material/AppBar';

const BlackAppBar = ({ children }) => (
    <AppBar position="static" sx={{ backgroundColor: 'black', color: 'white' }}>
        {children}
    </AppBar>
);

export default BlackAppBar;
