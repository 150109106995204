import React from 'react';
import { Box, Card, CardContent, CardMedia, Container, Typography, Divider } from '@mui/material';
import BlackAppBar from '../components/BlackAppBar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import RTImage from '../assets/RT.svg';
import usePageTitle from '../components/usePageTitle';

export default function ProductsPage() {
  usePageTitle('BEC | Products');

  return (
    <BlackAppBar>
      <Container
        maxWidth="xl"
        sx={{
          backgroundColor: '#000',
          color: 'white',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header />
        <Divider sx={{ backgroundColor: 'white' }} />

        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* Link wrapping the card to navigate to the external URL */}
          <a href="https://replicanttrader.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <Card sx={{ maxWidth: 345, backgroundColor: '#1c1c1c', color: 'white', textAlign: 'center' }}>
              <CardMedia
                component="img"
                height="140"
                image={RTImage}
                alt="Replicant Trader"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Replicant Trader
                </Typography>
                <Typography variant="body2" color="white" sx={{ opacity: 0.8 }}>
                  Replicant Trader is a trade simulation platform tailored for Kenya's market,
                  providing users with a realistic trading experience and essential resources.
                </Typography>
              </CardContent>
            </Card>
          </a>
        </Box>

        <Footer />
      </Container>
    </BlackAppBar>
  );
};
